import React from "react";
import AppRoutes from "./AppRoutes";

function App() {
  return (
    <div className="App">
    <AppRoutes/>  </div>
  )
}

export default App;

